import 'slick-carousel';

function initSliderMain() {
    const $sliderTop = $('.slider-top');
    if (!$sliderTop.length) {
        return
    }
    $sliderTop.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        dots: true,
        appendDots: $('.slider-top-dots')
    });

    document.addEventListener('turbolinks:before-cache', () => $sliderTop.slick('unslick'))
}

function initSliderArticles() {
    const $slidesChannels = $('.articles__slides');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 1,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        appendDots: $('.article__slides-dots'),
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}

function initSliderTrust() {
    const $slidesChannels = $('.trust__slides');
    if (!$slidesChannels.length) {
        return;
    }

    function setBoundries(slick, state) {
        if (state === 'default') {
            slick.find('ul.slick-dots li').eq(4).addClass('n-small-1');
        }
    }

    // Slick Selector.
    var divDots =  $('.trust__slides-dots');
    var maxDots = 4;
    var transformXIntervalNext = -18;
    var transformXIntervalPrev = 18;

    $slidesChannels.on('init', function (event, slick) {

        divDots.find('ul.slick-dots li').each(function (index) {
            $(this).addClass('dot-index-' + index);
        });
        divDots.find('ul.slick-dots').css('transform', 'translateX(0)');
        setBoundries(divDots,'default');
    });

    var transformCount = 0;
    $slidesChannels.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var totalCount = divDots.find('.slick-dots li').length;
        console.log(totalCount)
        if (totalCount > maxDots) {
            if (nextSlide > currentSlide) {
                if (divDots.find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('n-small-1')) {
                    if (!divDots.find('ul.slick-dots li:last-child').hasClass('n-small-1')) {
                        transformCount = transformCount + transformXIntervalNext;
                        divDots.find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('n-small-1');
                        var nextSlidePlusOne = nextSlide + 1;
                        divDots.find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('n-small-1');
                        divDots.find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                        var pPointer = nextSlide - 3;
                        var pPointerMinusOne = pPointer - 1;
                        divDots.find('ul.slick-dots li').eq(pPointerMinusOne).removeClass('p-small-1');
                        divDots.find('ul.slick-dots li').eq(pPointer).addClass('p-small-1');
                    }
                }
            }
            else {
                if (divDots.find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('p-small-1')) {
                    if (!divDots.find('ul.slick-dots li:first-child').hasClass('p-small-1')) {
                        transformCount = transformCount + transformXIntervalPrev;
                        divDots.find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('p-small-1');
                        var nextSlidePlusOne = nextSlide - 1;
                        divDots.find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('p-small-1');
                        divDots.find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                        var nPointer = currentSlide + 3;
                        var nPointerMinusOne = nPointer - 1;
                        divDots.find('ul.slick-dots li').eq(nPointer).removeClass('n-small-1');
                        divDots.find('ul.slick-dots li').eq(nPointerMinusOne).addClass('n-small-1');
                    }
                }
            }
        }
    });

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 1,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        appendDots: divDots,
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}

function initSliderProduct() {
    const $sliderProduct = $('.slider-product');
    if (!$sliderProduct.length) {
        return
    }
    $sliderProduct.slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        dots: true,
        appendDots: $('.slider-product-dots')
    });

    document.addEventListener('turbolinks:before-cache', () => $sliderProduct.slick('unslick'))
}


function initSliderIcons() {
    const $slidesChannels = $('.icon__slides__wrap');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 1,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        appendDots: $('.icon__slides__wrap-dots'),
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}


function initProductTabs() {
    const $slidesChannels = $('.pages__product__tab.products');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 1,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        // appendDots: $('.pages__product__tab-dots'),
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}


function initSliders() {
    initSliderMain();
    initSliderArticles();
    initSliderTrust();
    initSliderProduct();
    initSliderIcons();
    initProductTabs();
}

export default initSliders
