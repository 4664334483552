export default function () {
    const formRemote = document.querySelector("#form-remote");
    if (!formRemote) return;

    document.getElementById('popup_send').addEventListener('click', function (e) {
        grecaptcha.execute();
    });

    window.submitInvisibleRecaptchaForm = function () {
        formRemote.submit();
    }

    formRemote.addEventListener("ajax:success", (event) => {
        $.magnificPopup.close();
        $.magnificPopup.open({
            items: {
                src: '#popup-success',
            },
            type: 'inline',
            showCloseBtn: false,
            closeOnBgClick: false,
            callbacks: {
                beforeOpen() {
                    $('body').addClass('mfp-active')
                },
                beforeClose() {
                    $('body').removeClass('mfp-active')
                },
            },
        })
    });

    document.addEventListener("turbolinks:before-cache", function() {
        $('.g-recaptcha').empty();
    });

    formRemote.addEventListener('ajax:error', (event) => {
        Turbolinks.dispatch('turbolinks:load')
        scrollTo(0, 0)
    });

}
