import Rails from "@rails/ujs"
import Turbolinks from 'turbolinks';

let jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import './mobile/init.js'

Turbolinks.start();
Rails.start();
