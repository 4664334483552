export default function () {
    const sandwich = document.querySelector('.js-sandwich');
    const mobileMenu = document.querySelector('.js-mobile-menu');
    const mainHeaderInner = document.querySelector('.js-main-header-inner');

    const CLASS_SANDWICH_OPEN = 'header__button__open';
    const CLASS_MAIN_HEADER_INNER_FIXED = 'main-header__inner_fixed';
    const CLASS_MOBILE_MENU_OPEN = 'header__menu_open';

    const CLASS_BODY_SCROLL_HIDDEN = 'body_scroll-hidden';

    if (!sandwich || !mobileMenu) return;

    function onSandwichClick(e) {
        e.preventDefault();
        mainHeaderInner.classList.toggle(CLASS_MAIN_HEADER_INNER_FIXED);
        sandwich.classList.toggle(CLASS_SANDWICH_OPEN);
        mobileMenu.classList.toggle(CLASS_MOBILE_MENU_OPEN);
        document.body.classList.toggle(CLASS_BODY_SCROLL_HIDDEN);

    }

    sandwich.addEventListener('click', onSandwichClick);
}
