export default function () {
    const calculator = document.querySelector('.js-calculator');
    if (!calculator) return;

    const channelSelect = calculator.querySelector('.js-region-select');
    const citySelect = calculator.querySelector('.js-city-select');

    const aInput = document.getElementById('a-select');
    const bInput = document.getElementById('b-select');

    const divTemp = $('.js-data-temp');
    const divDay = $('.js-data-day');
    const divSquare = $('.js-data-square');

    const inputRegion = $('.js-input-region');
    const inputCity = $('.js-input-city');




    const CLASS_CALC_SECTOR = 'calc__sector';


    function initCalculator() {
        function initRegion() {
            $('.js-city-select').addClass("hidden");
            let city = document.getElementById(`city_region_${channelSelect.value}`)
            city.classList.remove("hidden");
            inputRegion.val(channelSelect.value);
            inputCity.val(city.value);

            divDay.html($(city).find("option:selected").attr('data-day'))
            divTemp.html($(city).find("option:selected").attr('data-temperature'))
            city.addEventListener('change', function () {
                inputCity.val(this.value);

                divDay.html($(city).find("option:selected").attr('data-day'))
                divTemp.html($(city).find("option:selected").attr('data-temperature'))
            });

        }

        function initMonthSelect() {
            channelSelect.addEventListener('change', function () {
                initRegion();
            });
        }


        initRegion()
        initMonthSelect()
    }

    function initSquare() {
        aInput.oninput = function() {
            divSquare.html(aInput.value * bInput.value)
        };

        bInput.oninput = function() {
            divSquare.html(aInput.value * bInput.value)
        };

    }

    initCalculator();
    initSquare();
}
