// import './polyfills.js'
import SliderSlickInit from './sliderSlick.js'
import PopupInit from './popupInit.js'
import FormInit from './form.js'
import CalculatorInit from './calculator.js'
import StepsInit from './steps'
import SandwichInit from './sandwich.js'
import LeftNav from './leftNav.js'

document.addEventListener('turbolinks:load', function () {
    PopupInit();
    SliderSlickInit();
    FormInit();
    CalculatorInit();
    StepsInit();
    SandwichInit();
    LeftNav();
});
