// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

import '../stylesheets/application_mobile.css'
import '../javascripts/application_mobile'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

try {
    const images = require.context('../images', true, /\.(jpg|jpeg|png|svg|ico)$/);
    const imagePath = (name) => images(name, true);
} catch (e) {
    console.log(e)
}

